.modal {
    display: flex;
    flex-direction: column;
    height: 86vh!important;

    .heading-container {
        font-weight: unset;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: var(--tui-font-size-medium);
        }
    }

    .switch {
        .switch-label {
            padding: 0;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }

    .inline {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        gap: var(--tui-spacing-large);

        .input {
            flex-grow: 1;
            flex-shrink: 0;
        }
    }

    .field-input {
        font-size: var(--tui-font-size-medium);
    }

    .terms-label {
        font-weight: var(--tui-font-weight-bold);
    }

    .input-section {
        border-radius: 0.25rem;
        height: 2.5rem;

        >* {
            padding-right: 0;
            width: 4.5rem;
        }

        :hover {
            background-color: unset;
        }
    }

    .end-year-input-section {
        >* {
            width: auto;
        }
    }
}
