.icons {
    --spacing: var(--tui-spacing-small);

    display: flex;
    align-items: center;
    gap: var(--spacing);

    >* {
        flex-shrink: 0;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.allow-shrink {
        >* {
            flex-shrink: initial;
        }
    }
}
