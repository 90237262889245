.text-output {
    &.inline-type {
        display: flex;
        align-items: center;
        gap: var(--tui-spacing-medium);
    }

    >.label {
        color: var(--tui-color-text);
        font-weight: var(--tui-font-weight-medium);
    }

    &.table-type {
        display: table-row;

        >.description,
        >.value,
        >.label {
            display: table-cell;
        }
    }

    &.with-label-colon {
        >.label {
            &:after {
                content: ':';
            }
        }
    }

    &.strong-value {
        >.value {
            font-weight: var(--tui-font-weight-bold);
        }
    }
}
