.list-view {
    --spacing: var(--tui-spacing-medium);

    &.vertical {
        display: flex;
        flex-direction: column;
        gap: var(--spacing);

        &.reverse {
            flex-direction: column-reverse;
        }
    }

    &.empty {
        &.pending {
            min-height: calc(2em + var(--tui-spacing-medium) * 2);
        }
    }
}
