.language-selection-input {
    .selection-label {
        font-size: var(--tui-font-size-extra-small);
    }

    .list {
        display: flex;

        .button {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            &.active {
                .label {
                    text-decoration: underline;
                }
            }

            &:not(:last-child) {
                &:after {
                    padding: 0 var(--tui-spacing-extra-small);
                    content: '|';
                }
            }
        }
    }
}
