.good-practice-item {
    border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
    border-radius: 0.25rem;

    .preview {
        width: auto;
        max-width: 12rem;
        height: 12rem;
        max-height: 19.9rem;
        object-fit: cover;
        object-position: top center;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: var(--tui-spacing-large);
        padding: var(--tui-spacing-small);
        color: var(--tui-color-text);

        .type {
            text-transform: uppercase;
            color: var(--tui-color-brand);
        }

        .country-list{
            .tags {
                color: var(--tui-color-brand);
            }
        }
    }

}
