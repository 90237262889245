.collapsible-content {
    .header-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: var(--tui-spacing-medium);
        font-weight: var(--tui-font-weight-bold);
        gap: var(--tui-spacing-medium);

        .header {
            flex-grow: 1;
        }

        .icon {
            flex-shrink: 0;
        }
    }

    .children {
        padding: 0 var(--tui-spacing-medium);
    }
}
