.tiny-mce-editor-input {
    .input-section {
        background-color: transparent;

        .input {
            padding: 0;

            .textLengthSection {
                display: flex;
                justify-content: space-evenly;
                font-size: var(--tui-font-size-small);

                .textLimit {
                    color: var(--color-orange);
                }
            }
        }
    }
}