.header {
    display: flex;
    flex-direction: column;
    gap: var(--tui-spacing-small);

    &.dark-mode {
        color: var(--tui-color-text-on-dark);
    }

    .heading-section {
        display: flex;
        gap: var(--tui-spacing-small);

        .heading-container {
            flex-grow: 1;

            .heading-title {
                line-height: 1;
            }

            .heading {
                flex-grow: 1;

                .heading-info {
                    padding: 0 var(--tui-spacing-small);
                    vertical-align: middle;
                    line-height: 1;
                    font-size: max(1.2rem, 0.5em);
                }
            }

            &.inline-heading-description {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: var(--tui-spacing-small);

                .heading {
                    flex-grow: unset;
                }

                .heading-description {
                    display: flex;
                    flex-direction: row;
                    gap: var(--tui-spacing-large);
                }
            }
        }
    }
}
