.raw-button {
    outline: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: fit-content;
    color: inherit;
    font-family: inherit;
    font-size: inherit;

    &.focused {
        outline-color: var(--tui-color-outline-light);
    }
}
