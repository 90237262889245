.good-practices {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--tui-spacing-ultra-large);

    .header-section {
        display: flex;
        position: relative;
        align-items: stretch;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        .profile {
            display: flex;
            align-items: center;
            flex-direction: column;
            background-color: var(--tui-color-brand);
            background-position: top center;
            background-size: cover;
            padding: var(--tui-spacing-extra-large) 0;
            width: 100%;
            color: var(--tui-color-text-on-dark);
            background-blend-mode: soft-light;
            gap: var(--tui-spacing-large);

            .container {
                display: flex;
                flex-direction: column;
                padding: var(--tui-spacing-large);
                width: 100%;
                max-width: var(--width-page-max);
                gap: var(--tui-spacing-large);
            }

            .language-selection {
                align-self: flex-end;
                max-width: var(--width-page-max);
            }
        }

    }
    .map {

        .map-wrapper {
            position: relative;
            flex-grow: 1;
            z-index: 0;
            margin-top: var(--tui-spacing-large);

            .map-container {
                width: 100%;
                height: 32rem;
            }
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        padding: var(--tui-spacing-large);
        width: 100%;
        max-width: var(--width-page-max);
        gap: var(--tui-spacing-ultra-large);

        .faq-section {
            display: flex;
            gap: var(--tui-spacing-super-large);

            .faq-list {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: var(--tui-spacing-large);

                .content {
                    display: flex;
                    flex-direction: column;
                    gap: var(--tui-spacing-small);

                    .separator {
                        border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
                    }
                }
            }

            .side-pane {
                display: flex;
                flex-basis: 30%;
                flex-direction: column;
                flex-shrink: 0;
                gap: var(--tui-spacing-medium);
                margin-top: 5rem;

                .block {
                    display: flex;
                    flex-direction: column;
                    border-radius: 0.25rem;
                    background-color: var(--tui-color-background);
                    padding: var(--tui-spacing-medium);
                    gap: var(--tui-spacing-medium);

                    .new-good-practice {
                        display: flex;
                    }
                }
            }

            @media (max-width: 1000px) {
                flex-wrap: wrap;

                .side-pane {
                    flex-basis: 100%;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .block {
                        flex-basis: 0;
                        flex-grow: 1;
                        min-width: 20rem;
                    }
                }
            }
        }

        .good-practices-container {
            display: flex;
            flex-direction: column;
            gap: var(--tui-spacing-small);

            .search-and-time-range-container {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: var(--tui-spacing-large);

                >* {
                    flex-basis: 0;
                    flex-grow: 1;
                    min-width: 20rem;

                    .input-section {
                        border-radius: 0.25rem;
                        background-color: var(--tui-filter-background-color);
                        height: 2.5rem;
                    }

                    .label {
                        padding-left: var(--tui-spacing-small);
                        color: var(--tui-color-label);
                        font-size: var(--tui-font-size-medium);
                    }
                }
            }

            .filter-container {
                display: flex;
                flex-wrap: wrap;
                gap: var(--tui-spacing-small);

                >* {
                    flex-basis: 0;
                    flex-grow: 1;
                    border-radius: .25rem;
                    min-width: 17rem;

                    .input-section {
                        border-radius: 0.25rem;
                        background-color: var(--tui-filter-background-color);
                        height: 2.5rem;

                        >*{
                            padding-right: 0;
                            width: 4.5rem;
                        }
                        :hover{
                            background-color: unset;
                        }
                    }
                    .label {
                        padding-left: var(--tui-spacing-extra-small-small);
                        color: var(--tui-color-label);
                        font-size: var(--tui-font-size-medium);
                    }
                }
            }

            .filter-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: var(--tui-spacing-large);

                .clear-all-container {
                    flex-basis: 100%;

                    .clear-filter-button {
                        color: var(--tui-color-danger);
                        gap: var(--tui-spacing-extra-small);
                    }
                }
            }

            .mobile-actions {
                display: flex;
                justify-content: flex-end;
            }

            .separator {
                border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
            }

            .ordering-container {
                display: flex;
                justify-content: flex-end;

                .order-dropdown{
                    margin-left: var(--tui-spacing-large);
                    color: var(--tui-color-brand);
                    gap: var(--tui-spacing-extra-small);

                }
            }

            .good-practice-list {
                display: flex;
                align-content: flex-start;
                flex-wrap: wrap;
                gap: var(--tui-spacing-extra-large);

                >* {
                    flex-basis: 0;
                    flex-grow: 1;
                    padding-right: var(--tui-spacing-ultra-small);
                    min-width: 20rem;
                }
            }

            .good-practice-grid {
                display: flex;
                flex-direction: column;
                gap: var(--tui-spacing-large);

                >*{
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 0;
                    height: 20rem;

                    >*{
                        flex-direction: column;

                        >*{
                            height:5.5rem;
                        }
                    }
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}

.mobile-filter-modal {
    .heading {
        text-transform: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: var(--tui-spacing-medium);
        gap: var(--tui-spacing-large);

        .search-and-time-range-container {
            display: flex;
            flex-direction: column;
            gap: var(--tui-spacing-large);

            >* {
                width: 100%;
            }
        }

        .mobile-filters {
            display: flex;
            flex-direction: column;
            gap: var(--tui-spacing-large);
        }

        .empty-content {
            flex-shrink: 0;
            height: 50vh;
        }

        .label {
            padding-left: var(--tui-spacing-small);
            color: var(--tui-color-label);
            font-size: var(--tui-font-size-medium);
        }
        .input-section {
            height: 3rem;
        }

        .radio-list {
            flex-direction: column;
        }
    }
    @media (max-width: 1000px) {
        .input-section {
            margin-right: var(--tui-spacing-medium);
            border-radius: 0.25rem;
            height: 3rem;
            >*{
                padding-right: 0;
                width: 4.5rem;
            }:hover{
                background-color: unset;
            }
        }
    }
}

body.modal-open {
    overflow: hidden;
}
