.filters-container {
    .displacement-legend {
        display: flex;
        flex-basis: 20%;
        flex-direction: column;
        gap: var(--tui-spacing-extra-small);

        .legend-element-list {
            display: flex;
            flex-grow: 1;
            gap: var(--tui-spacing-small);
            height: 3rem;
        }
    }
    .number-legend {
        display: flex;
        flex-basis: 40%;
        flex-direction: column;
        gap: var(--tui-spacing-extra-small);

        .legend-element-list {
            display: flex;
            flex-grow: 1;
            gap: var(--tui-spacing-small);
            height: 3rem;
        }
        @media (max-width: 1000px) {
            .legend-element-list {
                gap: var(--tui-spacing-extra-extra-small);
            }
        }
    }

    .time-range-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: var(--tui-spacing-medium);

        .time-range-input {
            width: 100%;
        }
    }
}

.disaster-button {
    background-color: var(--color-background-dark);
    height: 2.5rem;
}
