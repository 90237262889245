.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    padding: var(--tui-spacing-small) var(--tui-spacing-large);
    gap: var(--tui-spacing-medium);
    text-align: center;

    .icons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--tui-spacing-extra-small);
    }

    .children {
        display: flex;
        align-items: center;
        flex-grow: 0;
    }

    .actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--tui-spacing-extra-small);
    }

    &.primary {
        background-color: var(--tui-color-brand);
        color: var(--tui-color-text-on-dark);
    }

    &.action {
        background-color: transparent;
        padding: 0;
    }

    &.transparent {
        background-color: transparent;
        padding: 0;
        color: var(--tui-color-brand);
        font-weight: var(--tui-font-weight-bold);
    }

    &.secondary {
        background-color: var(--tui-color-foreground);
        color: var(--tui-color-brand);
        font-weight: var(--tui-font-weight-bold);

        .icons {
            color: var(--tui-color-brand);
        }

        .actions {
            color: var(--tui-color-brand);
        }
    }

    &:disabled {
        opacity: 0.2;
    }

    &:not(:disabled) {
        &:hover {
            &.primary {
                background-color: var(--tui-color-brand-hover);
            }

            &.secondary {
                background-color: var(--tui-color-background);
            }

            &.transparent,
            &.action {
                color: var(--tui-color-brand);

                &.dark-mode {
                    color: var(--tui-color-background);
                }
            }
        }
    }
}
