.alert {
    --spacing: var(--tui-spacing-small);
    --height: 100%;
    display: flex;
    position: absolute;
    gap: var(--tui-spacing-small);
    top: 0;
    left: 0;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    border-radius: var(--tui-radius-border-alerts);
    padding: var(--spacing);
    width: 100%;
    animation: appear var(--tui-duration-transition-medium) ease-in forwards;
    animation-delay: var(--tui-duration-delay-short);

    .icon {
        display: flex;
        align-items: flex-start;
        color: inherit;
    }

    .content {
        align-self: center;
    }

    .icon-container {
        font-size: 1.4em;
    }

    .action-container {
        align-items: flex-start;
        font-size: 1.1em;
    }

    &.info {
        background-color: var(--tui-color-background-dark);
        color: var(--tui-color-text-on-dark);
    }

    &.error {
        background-color: var(--tui-color-danger);
        color: var(--tui-color-text-on-danger);
    }

    &.success {
        background-color: var(--tui-color-success);
        color: var(--tui-color-text-on-success);
    }

    &.hidden {
        position: initial;
        visibility: visible;
        opacity: 1;
        height: var(--height);
        overflow: hidden;
        animation: disappear var(--tui-duration-transition-medium) ease-in forwards;
        animation-delay: var(--tui-duration-delay-short);
    }

    @keyframes appear {
        from {
            position: initial;
            visibility: visible;
            opacity: 0;
            padding: 0 var(--spacing);
            height: 0;
        }

        to {
            position: initial;
            visibility: visible;
            opacity: 1;
            padding: var(--spacing);
            height: var(--height);
        }
    }

    @keyframes disappear {
        from {
            position: initial;
            visibility: visible;
            opacity: 1;
            padding: var(--spacing);
            height: var(--height);
        }

        to {
            opacity: 0;
            padding: 0 var(--spacing);
            height: 0;
        }
    }
}

