.ellipsized-content {
    max-width: var(--width-readable-content-max);

    &.dark-mode {
        color: var(--tui-color-text-on-dark);
    }

    .footer {
        display: block;
        padding: var(--tui-spacing-medium) 0;
    }

    .ellipsize-toggle-button {
        display: flex;
        align-items: center;
        margin-top: var(--tui-spacing-medium);
        gap: var(--tui-spacing-small);
        font-weight: var(--tui-font-weight-bold);
    }
}

