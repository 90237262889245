.dismissable-list-output {
    display: flex;
    align-items: baseline;
    gap: var(--tui-spacing-small);
    flex-wrap: wrap;

    .label {
        font-weight: var(--tui-font-weight-bold);
    }

    .value-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--tui-spacing-small);

        .tag {
            display: flex;
            align-items: center;
            background-color: var(--tui-color-background);
            padding: var(--tui-spacing-super-small) var(--tui-spacing-small);
            gap: var(--tui-spacing-small);
        }
    }
}
