.good-practice-item {
    --image-height: 25rem;
    --image-width: 100%;
    display: flex;
    flex-direction: column;
    border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
    gap: var(--tui-spacing-medium);

    .cover-wrapper {
        width: var(--image-width);

        .cover-image {
            background-color: var(--tui-color-background);
            width: 100%;
            height: var(--image-height);
            object-fit: cover;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: var(--tui-spacing-medium) var(--tui-spacing-small);
        gap: var(--tui-spacing-small);

        .link {
            color: var(--tui-color-brand);
        }

        .type {
            text-transform: uppercase;
            font-size: var(--tui-font-size-small);
        }

        .date {
            color: var(--tui-color-text-label);
        }
    }
}
