.good-practices {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--tui-spacing-super-large);

    .header-section {
        display: flex;
        position: relative;
        align-items: stretch;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        .basic-info {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
            background-color: var(--tui-color-brand);
            background-position: top center;
            background-size: cover;
            padding: var(--tui-spacing-extra-large) 0;
            width: 100%;
            min-height: 20rem;
            color: var(--tui-color-text-on-dark);
            background-blend-mode: soft-light;
            gap: var(--tui-spacing-large);

            .breadcrumbs {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-radius: 0.25rem;
                gap: var(--tui-spacing-small);
                font-size: var(--tui-font-size-large);

                >* {
                    display: inline-block;
                    color: var(--tui-color-text-on-dark);
                }
            }

            .container {
                display: flex;
                flex-direction: column;
                padding: var(--tui-spacing-large);
                width: 100%;
                max-width: var(--width-page-max);
                gap: var(--tui-spacing-large);
            }

            .language-selection {
                align-self: flex-end;
                max-width: var(--width-page-max);
            }
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        padding: var(--tui-spacing-large);
        width: 100%;
        max-width: var(--width-page-max);
        gap: var(--tui-spacing-extra-large);

        .details {
            display: flex;
            gap: var(--tui-spacing-super-large);

            .main-section {
                display: flex;
                flex-direction: column;
                gap: var(--tui-spacing-large);
                flex-grow: 1;
                overflow: hidden;

                .meta {
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: 0.25rem;
                    gap: var(--tui-spacing-medium);
                    background-color: var(--tui-color-background);

                    >* {
                        flex-basis: 0;
                        flex-grow: 1;
                        padding: var(--tui-spacing-medium) var(--tui-spacing-large);
                    }

                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                }
            }

            .side-pane {
                display: flex;
                flex-basis: 30%;
                flex-direction: column;
                flex-shrink: 0;
                min-width: 20rem;
                gap: var(--tui-spacing-large);

                .carousel {
                    min-height: 16rem;

                    .carousel-item {
                        .video-embed {
                            border: 0;
                            width: 100%;
                            height: 16rem;
                        }

                        .image {
                            width: 100%;
                            height: 16rem;
                            object-fit: cover;
                            object-position: top center;
                        }

                        .description {
                            color: var(--tui-color-text);
                        }
                    }

                    .carousel-actions {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0.25rem;

                        >* {
                            display: flex;
                            align-items: center;
                            padding: var(--tui-spacing-extra-small);
                        }
                    }
                }

                .block {
                    display: flex;
                    flex-direction: column;
                    border-radius: 0.25rem;
                    background-color: var(--tui-color-background);
                    padding: var(--tui-spacing-large);
                    gap: var(--tui-spacing-small);
                }

                .block-list {
                    display: flex;
                    flex-direction: column;
                    gap: var(--tui-spacing-large);

                    .block {
                        display: flex;
                        flex-direction: column;
                        border-radius: 0.25rem;
                        background-color: var(--tui-color-background);
                        padding: var(--tui-spacing-large);
                        gap: var(--tui-spacing-small);
                    }
                }
            }

            @media (max-width: 1000px) {
                flex-wrap: wrap;

                .side-pane {
                    flex-basis: 100%;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .carousel-container {
                        flex-basis: 0;
                        flex-grow: 1.5;
                        min-width: 20rem;
                    }

                    .block-list {
                        flex-basis: 0;
                        flex-grow: 1;
                        min-width: 20rem;
                    }
                }
            }
        }

        .media-and-resource-links {
            display: flex;
            flex-direction: column;
            gap: var(--tui-spacing-super-large);
        }

        .related-section {
            display: flex;
            flex-direction: column;
            gap: var(--tui-spacing-super-large);

            .related-good-practice-list {
                display: flex;
                flex-wrap: wrap;
                gap: var(--tui-spacing-extra-large);

                >* {
                    flex-basis: 0;
                    flex-grow: 1;
                    min-width: 16rem;
                    >*{
                        width: 100%;
                        max-width: none;
                    }
                }
            }
        }
    }
}

.expanded-carousel-item {
    .video-embed {
        border: 0;
        width: 100%;
        height: calc(100% - 4rem);
    }

    .image {
        background-color: var(--tui-color-background);
        width: 100%;
        height: calc(100% - 4rem);
        object-fit: contain;
        object-position: center center;
    }

    .description {
        padding: var(--tui-spacing-medium) 0;
    }
}
