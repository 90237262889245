.country-profile {
    display: flex;
    flex-direction: column;

    .header-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: var(--tui-color-brand-light);
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        color: var(--color-text-on-dark);
        background-blend-mode: multiply;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: var(--tui-spacing-extra-large);
        width: 100%;
        max-width: var(--width-page-max);
        gap: var(--tui-spacing-extra-large);

        .profile {
            display: flex;
            flex-direction: column;
            gap: var(--tui-spacing-large);

            .profile-heading {
                color: var(--color-text-on-dark);
            }
        }

        .navbar {
            display: flex;
            position: sticky;
            top: 0;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 var(--tui-spacing-large);
            gap: var(--tui-spacing-extra-large);

            .nav-link {
                text-align: center;
                color: var(--color-text-on-dark);
            }
        }
    }

    .body-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .content {
            display: flex;
            flex-direction: column;
            padding: var(--tui-spacing-extra-large);
            width: 100%;
            max-width: var(--width-page-max);
            gap: var(--tui-spacing-extra-large);

            .overview {
                display: flex;
                flex-direction: column;
                padding: var(--tui-spacing-super-large) 0;
                gap: var(--tui-spacing-large);

                .overview-content {
                    display: flex;
                    align-items: flex-start;
                    gap: var(--tui-spacing-medium);
                    flex-wrap: wrap;

                    .tab-list {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        flex-shrink: 0;
                        background-color: transparent;
                        width: 12rem;
                        gap: var(--tui-spacing-small);

                        .tab {
                            width: 100%;
                        }
                    }

                    .text-output {
                        gap: var(--tui-spacing-small);
                        flex-grow: 1;

                        .value {
                            color: var(--tui-color-text-label);
                        }
                    }
                }
            }

            .displacement-data {
                display: flex;
                flex-direction: column;
                padding: var(--tui-spacing-super-large) 0;
                gap: var(--tui-spacing-extra-large);

                .conflict-header {
                    background-color: var(--color-conflict);

                    .conflict-heading {
                        color: var(--color-text-on-dark);
                    }
                }

                .conflict-button {
                    background-color: var(--color-conflict);
                    height: 2.5rem;
                }

                .disaster-header {
                    background-color: var(--color-disaster);

                    .disaster-heading {
                        color: var(--color-text-on-dark);
                    }
                }

                .disaster-button {
                    background-color: var(--color-disaster);
                    height: 2.5rem;
                }

                .infographics {
                    display: flex;
                    flex-direction: column;
                    gap: var(--tui-spacing-large);

                    :global {
                        .recharts-legend-item-text {
                            font-size: var(--tui-font-size-small);
                        }

                        .recharts-cartesian-axis-tick-value {
                            font-size: var(--tui-font-size-small);
                        }
                    }

                    .infographic-list {
                        display: flex;
                        flex-wrap: wrap;

                        >* {
                            flex-basis: 0;
                            flex-grow: 1;
                            padding: var(--tui-spacing-medium);
                            min-width: 15rem;
                        }

                        .conflict-infographic {
                            color: var(--color-conflict);

                            .heading {
                                color: var(--color-conflict);
                            }
                        }

                        .disaster-infographic {
                            color: var(--color-disaster);

                            .heading {
                                color: var(--color-disaster);
                            }
                        }
                    }
                }
            }

            .idu-container {
                display: flex;
                flex-wrap: wrap;
                gap: var(--tui-spacing-large);
                padding: var(--tui-spacing-large) 0;

                .idu {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    flex-grow: 1;
                    border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
                    padding: var(--tui-spacing-medium);
                    max-width: calc(50% - var(--tui-spacing-medium));
                    gap: var(--tui-spacing-medium);

                    @media screen and (max-width: 900px) {
                        max-width: unset;
                    }

                    .displacementIcon {
                        display: flex;
                        align-items: baseline;
                        gap: var(--tui-spacing-medium);

                        .icon {
                            flex-shrink: 0;
                            height: 2rem;
                        }
                    }
                }

                .idu-pager {
                    display: flex;
                    flex-basis: 100%;
                    flex-wrap: wrap;
                    gap: var(--tui-spacing-large);
                }
            }

            .internal-displacement-updates {
                display: flex;
                flex-direction: column;
                gap: var(--tui-spacing-large);
            }

            .related-material {
                display: flex;
                flex-direction: column;
                gap: var(--tui-spacing-large);

                .material-list {
                    --gap: var(--tui-spacing-large);
                    display: flex;
                    flex-wrap: wrap;
                    gap: var(--gap);

                    .material {
                        --image-height: 20rem;
                        --image-width: 10rem;
                        flex-basis: calc(100% / 2 - var(--gap));
                        flex-direction: row;
                        flex-grow: 1;

                        >* {
                            flex-basis: 0;
                            flex-grow: 1;
                        }
                    }
                }

                .material-pager {
                    display: flex;
                    flex-basis: 100%;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    gap: var(--tui-spacing-large);
                }
            }

            .misc {
                display: flex;
                gap: var(--tui-spacing-extra-large);
                flex-wrap: wrap;

                .essential-reading {
                    display: flex;
                    flex-direction: column;
                    gap: var(--tui-spacing-large);
                }

                .contact {
                    display: flex;
                    flex-direction: column;
                    gap: var(--tui-spacing-large);

                    .contact-item {
                        display: flex;
                        background-color: var(--color-background);
                        max-width: 30rem;
                        gap: var(--tui-spacing-large);

                        .preview {
                            width: 10rem;
                            height: 10rem;
                            object-fit: cover;
                        }

                        .contact-details {
                            padding: var(--tui-spacing-small);
                        }
                    }
                }
            }
        }
    }

    .select-input {
        .input-section {
            border-radius: 0.25rem;
            background-color: var(--tui-filter-background-color);
            height: 2.5rem;

            >*{
                padding-right: 0;
                width: 4.5rem;
            }:hover{
                background-color: unset;
            }
        }
    }
    @media (max-width: 1000px) {
        .time-range-container {
            padding-right: var(--tui-spacing-medium);
        }
        .select-input {
            .input-section {
                margin-right: var(--tui-spacing-medium);
                border-radius: 0.25rem;
                height: 3rem;
            }
        }
    }
}
