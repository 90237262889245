.slider {
    display: flex;
    position: relative;
    z-index: 0;
    margin: auto 0;

    .heading-description {
        color: var(--tui-color-text-label);
    }

    .react-slider {
        width: 100%;
        height: 2ch;

        .thumb {
            border: 2px solid var(--tui-color-brand);
            border-radius: 50%;
            background-color: var(--color-foreground);
            cursor: grab;
            width: 2ch;
            height: 2ch;
            text-align: center;
        }

        .track {
            top: 1ch;
            background-color: var(--tui-color-separator);
            height: 2px;

            &.center {
                background-color: var(--tui-color-brand);
            }
        }

        .mark {
            transform: translate(calc(1ch + 0.5px), -0.5ch);
            transition: opacity .2s ease-in-out;
            opacity: 0.3;
            border-radius: 50%;
            background-color: var(--tui-color-brand);
            width: var(--tui-width-separator-thin);
            height: 1.5ch;
        }
    }

    /*
    &:hover {
        .react-slider {
            .mark {
                opacity: 0.2;
            }
        }
    }
    */
}
