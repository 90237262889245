.container {
    border: var(--tui-width-separator-thin) solid var(--tui-color-separator);

    .header {
        background-color: var(--tui-color-background);
        padding: var(--tui-spacing-medium);
    }

    .filters {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: var(--tui-spacing-large);
        border-bottom: var(--tui-width-separator-thin) solid var(--tui-color-separator);
        padding: var(--tui-spacing-medium);

        >* {
            flex-basis: 0;
            flex-grow: 1;
            min-width: 15rem;
        }
    }

    .footer-container {
        display: flex;
        border-top: var(--tui-width-separator-thin) solid var(--tui-color-separator);
        padding: var(--tui-spacing-medium);

        .footer {
            flex-grow: 1;
        }

        .footer-actions {
            display: flex;
            gap: var(--tui-spacing-medium);
            flex-wrap: wrap;

            >* {
                flex-basis: 0;
                flex-grow: 1;
                min-width: 18rem;
            }
        }
    }
}
