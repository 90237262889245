.tooltip {
    position: fixed;
    z-index: 11;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .3);
    background-color: var(--color-foreground);
    padding: var(--tui-spacing-medium);
    max-width: 20rem;
    line-height: 1.5;
    color: var(--tui-color-text);
    font-family: var(--tui-font-family-sans-serif);
    font-size: var(--tui-font-size-medium);
    /* font-weight: var(--tui-font-weight-light); */
}
