.infographic {
    display: flex;
    flex-direction: column;
    min-width: 14rem;
    gap: var(--tui-spacing-large);

    .total-value {
        color: currentColor;
        font-size: var(--tui-font-size-super-large);
        font-weight: var(--tui-font-weight-bold);
    }

    .description-and-date {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--tui-spacing-medium);

        .description {
            color: currentColor;
            font-size: var(--tui-font-size-large);
            font-weight: var(--tui-font-weight-bold);
        }

        .date {
            color: rgba(0, 0, 0, .5);
        }
    }

    .chart {
        display: flex;
        position: relative;
        z-index: 0;
        width: 100%;
        height: 14rem;

        :global {
            .recharts-tooltip-wrapper {
                width: 12rem;

                .recharts-default-tooltip {
                    width: 100%;
                    white-space: unset!important;
                    font-size: var(--tui-font-size-small);
                }
            }
        }
    }
}
