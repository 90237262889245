.legend-element {
    display: flex;
    align-items: center;
    gap: var(--tui-spacing-small);
    flex-grow: 1;
    justify-content: center;

    .circle-container {
        display: flex;
        justify-content: center;

        .circle {
            border-radius: 2rem;
            height: 1rem;
        }
    }

    &.clickable {
        border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
        border-radius: 0.25rem;
        padding: var(--tui-spacing-small) var(--tui-spacing-medium);

        &.is-active {
            border-color: var(--tui-color-brand);
            background-color: var(--tui-color-background);
        }
    }
}
