.tab-list {
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 0;

    .step-border {
        position: absolute;
        top: calc((#{var(--tui-spacing-medium)} + 1rem) / 2);
        z-index: -1;
        background-color: var(--tui-color-separator);
        width: 100%;
        height: var(--tui-width-separator-medium);

        .progress {
            transition: var(--tui-duration-transition-medium) width ease-in-out;
            background-color: var(--tui-color-brand);
            width: 0%;
            height: 100%;
        }
    }

    .start-dummy-content,
    .end-dummy-content {
        align-self: flex-end;
        flex-grow: 1;
        border-bottom: var(--tui-width-separator-thin) solid var(--tui-color-separator);
    }

    &.disabled {
        .step-border {
            opacity: 0.5;
        }
    }

    &.secondary {
        border-radius: calc(1em + #{var(--tui-spacing-medium)});
        background-color: var(--tui-color-background);
        width: fit-content;
    }
}
